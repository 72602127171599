<template>
  <div>
    <feather-icon
      v-if="this.clientStatus == 2 && this.stateHold == 1"
      id="iconTriangle"
      icon="CircleIcon"
      size="14"
      class="iconTriangle"
    />
    <feather-icon
      v-else
      id="icon"
      icon="CircleIcon"
      size="14"
      :class="colorStatus"
    />
    <feather-icon
      v-if="this.clientStatus == 2 && this.stateHold == 2"
      id="icon"
      icon=" CircleIcon"
      size="14"
      :class="colorStatus"
    />
  </div>
</template>
<script>
export default {
  props: {
    clientStatus: {
      required: true,
      type: Number,
    },
    stateHold: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    colorStatus() {
      let selected;

      switch (this.clientStatus) {
        case 1:
          selected = "green";
          break;
        case 2:
          selected = "red";
          break;
        case 3:
          selected = "blue";
          break;
        case 4:
          selected = "red";
          break;
        case 5:
          selected = "red";
          break;
        case 6:
          selected = "red";
          break;
      }
      return selected;
    },
  },
};
</script>
<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
  border: none;
}
.iconTriangle {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid #ffc107;
}
.green {
  color: #00cc00;
  border-color: #00cc00;
  background: #00cc00;
}
.red {
  color: red;
  border-color: red;
  background: red;
}
.blue {
  color: #0066ff;
  border-color: #00cc00;
  background: #0066ff;
}
</style>